(function ($) {


const Init = (() => {

  let website      = {},
      load         = {},
      resize       = {},
      scroll       = {},
      mod          = {},
      scrollOption = {},
      parallax     = {},
      drawer       = {},
      navi         = {},
      form         = {},
      formMethod   = {},
      timer        = {},
      wp           = {},
      iscroll      = {},
      loading      = {},
      loopSlide    = {},
      totop        = {},
      mmenu        = {};

  website = {
    page          : document.body.dataset.page,
    url           : document.URL,
    winWidth      : window.innerWidth,
    winHeight     : window.innerHeight,
    scrollY       : window.pageYOffset,
    breakpoint    : { pc: 1200, tb: 992, sp: 768, ss: 576 },
    ua            : navigator.userAgent.toLowerCase(),
    isDesktop     : true,
    isFirst       : true,
    animationFrame: null,

    headTag       : $('.l-header'),

    act           : 'is-act',
    actOpen       : 'is-open',

    targetAnime : 'js-anime',
    actAnime    : 'is-anime',
    shiftPoint  : 0,
    shiftPc     : 5,
    shiftSp     : 10,
  };
  website.shiftPoint = website.breakpoint.tb;

  load = {
    functions: [],
    length   : 0,
  };

  resize = {
    functions: [],
    length   : 0,
    // fps      : 60,
    isRunning: false,
    animationFrame: null,
  };

  scroll = {
    functions: [],
    length   : 0,
    // fps      : 60,
    isRunning: false,
    animationFrame: null,
  };


  const WinLoad = {

    init() {
      window.addEventListener('load', function(e) {
        WinLoad.update();
      });

      console.log('!-- load init --!');
    },

    add(func) {
      load.functions.push(func);
      load.length = load.functions.length;
    },

    remove(func) {
      load.functions.splice(func, 1);
      load.length = load.functions.length;
    },

    update() {
      for ( let i = 0; i < load.length; i++ ) {
        let func = load.functions[i];
        func();
      }
    },

  }


  const WinResize = {

    init() {
      window.addEventListener('resize', function(e) {
        if ( !resize.isRunning ) {
          resize.isRunning = true;

          resize.animationFrame = window.requestAnimationFrame( WinResize.update );

          // if ( window.requestAnimationFrame ) {
          //   window.requestAnimationFrame(() => {
          //     WinResize.update();
          //   });
          // } else {
          //   setTimeout(() => {
          //     WinResize.update();
          //   }, 1000/resize.fps);
          // }
        }
      });

      console.log('!-- resize init --!');
    },

    add(func) {
      resize.functions.push(func);
      resize.length = resize.functions.length;
    },

    remove(func) {
      resize.functions.splice(func, 1);
      resize.length = resize.functions.length;
    },

    update() {
      website.winWidth  = window.innerWidth;
      website.winHeight = window.innerHeight;
      website.ua        = navigator.userAgent.toLowerCase();

      for ( let i = 0; i < resize.length; i++ ) {
        let func = resize.functions[i];
        func();
      }

      resize.isRunning = false;
    },

  }


  const WinScroll = {

    init() {
      window.addEventListener('scroll', function(e) {
        if ( !scroll.isRunning ) {
          scroll.isRunning = true;

          scroll.animationFrame = window.requestAnimationFrame( WinScroll.update );

          // if ( window.requestAnimationFrame ) {
          //   window.requestAnimationFrame(() => {
          //     WinScroll.update();
          //   });
          // } else {
          //   setTimeout(() => {
          //     WinScroll.update();
          //   }, 1000/scroll.fps);
          // }
        }
      });

      console.log('!-- scroll init --!');
    },

    add(func) {
      scroll.functions.push(func);
      scroll.length = scroll.functions.length;
    },

    remove(func) {
      scroll.functions.splice(func, 1);
      scroll.length = scroll.functions.length;
    },

    update() {
      website.winHeight = window.innerHeight;
      website.scrollY = window.pageYOffset;

      for ( let i = 0; i < scroll.length; i++ ) {
        let func = scroll.functions[i];
        func();
      }

      scroll.isRunning = false;
    },

  }


  const Modules = {

    init() {
      mod = {
        targetSmooth    : 'js-scroll',
        targetTotop     : 'js-totop',
        smoothSpeed     : 600,

        targetTab       : 'js-tabWrap',
        targetTabTrigger: 'js-tabTrg',
        targetTabContent: 'js-tabCont',

        targetAccordion : 'js-accordion',

        targetModal : 'js-modal',
        targetModalOpen : 'js-modalOpen',
        targetModalClose: 'js-modalClose',

        targetAlign     : 'js-align',
        targetAlignList : [],
        alignAddClass   : 'c-alignheight',

        targetOfi       : '.js-ofi .ofi',

        targetMenu : 'js-menu',
        targetPagenavi : 'js-pagenavi',

        targetMegaWrap  : 'js-megaWrap',
        targetMegaTrg   : 'js-megaTrg',
        targetMegaCont  : 'js-megaCont',

        targetDateField : 'js-datepicker',

        targetPullTrg   : 'js-pulldown',
        targetPullConts : 'js-pulldown_conts',

      };

      let alignList = document.querySelectorAll('.' + mod.targetAlign);
      mod.targetAlignList = Array.prototype.slice.call(alignList, 0);

      this.iosCheck();
      this.deviceCheck();
      this.smoothScroll();
      this.tabBtn();
      this.accordionBtn();
      this.modalBtn();
      this.pulldown();

      objectFitImages(mod.targetOfi);
      Stickyfill.add('.js-sticky');
    },

    iosCheck() {
      if ( website.ua.indexOf('ipad') > -1 || website.ua.indexOf('iphone') > -1 || (website.ua.indexOf('macintosh') > -1 && 'ontouchend' in document ) ) {
        $('body').addClass('ios');
      } else {
        $('body').removeClass('ios');
      }
    },

    deviceCheck() {
      if (
        website.ua.indexOf('iphone') > -1 ||
        website.ua.indexOf('ipod') > -1 ||
        website.ua.indexOf('android') > -1 && website.ua.indexOf('mobile') > -1
      ) {
        website.isDesktop = false;
        $('body').removeClass('pc tablet').addClass('sp');
      } else if (
        website.ua.indexOf('ipad') > -1 ||
        website.ua.indexOf('Android') > -1 ||
        (website.ua.indexOf('macintosh') > -1 && 'ontouchend' in document )
      ) {
        website.isDesktop = false;
        $('body').removeClass('pc sp').addClass('tablet');
      } else {
        website.isDesktop = true;
        $('body').removeClass('tablet sp').addClass('pc');
      }
    },

    smoothScroll() {
      $('.' + mod.targetSmooth + ':not([href=""])').on('click', function() {
        let href     = $(this).attr("href"),
            target   = $( (href == "#") ? 'html' : href ),
            position;
        if($('.'+mod.targetMenu).length){
          shift    = ( (href != "#") ) ? $('.'+mod.targetMenu).outerHeight() : 0
        }else{
          shift    = 50;
        }
        position = target.offset().top - shift;
        $('body,html').animate({scrollTop: position}, mod.smoothSpeed, 'swing');
        return false;
      });
    },

    tabBtn() {
      $('.' + mod.targetTabTrigger).each(function(){
        let tabBtn = $(this).children('li').children('a');
        tabBtn.on('click',function(){
          let tabHref = $(this).attr('href'),
              tabObj = $(this).parent('li').siblings('li').children('a'),
              targetCont = $(this).closest('.'+mod.targetTab).find('.'+mod.targetTabContent),
              targetObj = targetCont.find(tabHref),
              contsObj = targetObj.siblings();
              tabObj.removeClass('is-act');
              $(this).addClass('is-act');
              contsObj.hide();
              targetObj.fadeIn();
              return false;
        });
        $(this).children('li').eq(0).children('a').click();
      });
    },

    accordionBtn() {
      $('.' + mod.targetAccordion).on('click', function(){
        if ( $(this).hasClass(website.act) ) {
          $(this).removeClass(website.act).next().stop().slideUp('fast');
          // $(this).removeClass(website.act);
        } else {
          $(this).addClass(website.act).next().stop().slideDown();
          // $(this).addClass(website.act);
        }
      });
    },

    modalBtn() {
      $('.'+mod.targetModalOpen).on('click',function(){
        $('body').addClass('is-modalOpen');
        let target = $(this).attr('href');
        $(target).fadeIn();
        return false;
      });
      $('.'+mod.targetModalClose).on('click',function(){
        $('body').removeClass('is-modalOpen');
        $(this).closest('.'+mod.targetModal).fadeOut();
        return false;
      });
    },

    alignHeight() {
      let alignHeight  = 0;

      mod.targetAlignList.forEach((target) => {
        target.style.minHeight = 'auto';
        target.classList.add(mod.alignAddClass);
        alignHeight = ( target.offsetHeight > alignHeight ) ? target.offsetHeight: alignHeight;
        target.classList.remove(mod.alignAddClass);
      });
      mod.targetAlignList.forEach((target) => {
        target.style.minHeight = alignHeight + 'px';
      });
    },

    megaMenu (){
      if(website.winWidth >= website.shiftPoint){
        $('.'+mod.targetMegaTrg).hover(
          function(){
            let positionY = $('.'+mod.targetMegaWrap).offset().top + $('.'+mod.targetMegaWrap).height();
            $('.'+mod.targetMegaCont).css('top', positionY+'px');
            $(this).addClass('is-act');
            $(this).children('.'+mod.targetMegaCont).addClass('is-act').stop().slideDown();
          },
          function(){
            $(this).removeClass('is-act');
            $(this).children('.'+mod.targetMegaCont).removeClass('is-act').stop().slideUp();
          }
        );
      }else{
        $('.'+mod.targetMegaTrg).children('a').on('click',function(){
          $(this).parent('.'+mod.targetMegaTrg).toggleClass('is-act');
          $(this).parent('.'+mod.targetMegaTrg).find('.'+mod.targetMegaCont).toggleClass('is-act').stop().slideToggle();
        });
      }
    },
    pulldown (){
      if(website.winWidth >= website.shiftPoint){
        $('.'+ mod.targetPullTrg).hover(
          function(){
            $(this).addClass('is-act');
            $(this).children('.'+mod.targetPullConts).addClass('is-act').stop().slideDown();
          },
          function(){
            $(this).removeClass('is-act');
            $(this).children('.'+mod.targetPullConts).removeClass('is-act').stop().slideUp();
          }
        )
      } else{
        $('.'+mod.targetPullTrg).children('a').on('click',function(){
          $(this).parent('.'+mod.targetPullTrg).find('.'+mod.targetPullConts).toggleClass('is-act').stop().slideToggle();
          if(!$(this).parent('.'+mod.targetPullTrg).hasClass('is-act')){
            $(this).parent('.'+mod.targetPullTrg).toggleClass('is-act');
            return false;
          }else{
            $(this).parent('.'+mod.targetPullTrg).toggleClass('is-act');
          }
        });
      }
    }
  }


  const Animation = {

    init() {
    },

    check() {
      $('.' + website.targetAnime + ':not(.' + website.actAnime + ')').each(function(){
        let self = $(this),
            targetPosition = self.offset().top,
            triggerPosition,
            shift;

        if( website.winWidth < website.shiftPoint ) {
          shift = website.winHeight / website.shiftSp;
        } else {
          shift = website.winHeight / website.shiftPc;
        }
        triggerPosition = targetPosition - website.winHeight + shift;

        if( website.scrollY > triggerPosition ){
          self.addClass(website.actAnime);
        }
      });
    },

  }

  const Timer = {
    init() {
      timer = {
        targetClass : '.js-timer',
      };
      this.check();
    },
    check() {
      if($(timer.targetClass).length){
        $(timer.targetClass).each(function(){
          let startTime   = new Date($(this).data('start')),
              endTime     = new Date($(this).data('end')),
              currentTime = new Date();
          if(!(startTime <= currentTime && currentTime <= endTime)){
            $(this).hide();
          }
        });
      }
    }
  }


  const Iscroll = {
    init() {
      iscroll = {
        targetClass : 'js-iScroll',
      }
      iscroll.count = $('.'+iscroll.targetClass).data('count');
      iscroll.step = $('.'+iscroll.targetClass).data('step');
      $('.'+iscroll.targetClass).attr('data-max',$('.'+iscroll.targetClass).children().length);
      iscroll.max = $('.'+iscroll.targetClass).data('max');
      $('.'+iscroll.targetClass).children().eq(iscroll.count-1).addClass('is-show').prevAll().addClass('is-show');
      $('.'+iscroll.targetClass).children().eq(iscroll.count-1).nextAll().hide();
    },
    check(){
      if($('.'+iscroll.targetClass).length){
        if($('.'+iscroll.targetClass).height() + $('.'+iscroll.targetClass).offset().top < website.scrollY + website.winHeight){
          if($('.'+iscroll.targetClass).attr('data-count') < $('.'+iscroll.targetClass).children().length){
            for(let i = 0; i < iscroll.step; i++){
              if(iscroll.count < iscroll.max){
                $('.'+iscroll.targetClass).children().eq(iscroll.count).addClass('is-show').fadeIn();
                iscroll.count++;
              }
            }
            $('.'+iscroll.targetClass).attr('data-count',iscroll.count);
          }
        }
      }
    }
  }



  // const PageScroll = {

  //   init() {
  //     scrollOption = {
  //       target    : document.getElementById('js-contbox'),
  //       translateY: 0,
  //       contHeight: 0,
  //       velocity  : 0.08,

  //       rootMargin : '0px',
  //       threshold  : 0.1,
  //     };

  //     this.observer();
  //   },

  //   set() {
  //     scrollOption.contHeight    = scrollOption.target.getBoundingClientRect().height - 1;
  //     document.body.style.height = Math.floor(scrollOption.contHeight) + 'px';
  //   },

  //   smooth() {
  //     this.set();

  //     let diff = website.scrollY - scrollOption.translateY;

  //     if ( diff !== 0 ) {
  //       if ( Math.abs(diff) > 1 ) {
  //         scrollOption.translateY += diff * scrollOption.velocity;
  //       } else {
  //         scrollOption.translateY = website.scrollY;
  //       }
  //       scrollOption.target.style.transform = 'translate3d(0, -' + scrollOption.translateY + 'px, 0)';
  //     }
  //   },

  //   observer() {
  //     let targets = Array.from( document.querySelectorAll('.' + website.targetAnime) ),
  //         options = {
  //           rootMargin : scrollOption.rootMargin,
  //           threshold  : scrollOption.threshold,
  //         };
  //     let observer = new IntersectionObserver(this.observerCallback, options);

  //     targets.forEach((target) => {
  //       if ( target.dataset.delay ) {
  //         target.style.transitionDelay = target.dataset.delay + 'ms';
  //       }

  //       observer.observe(target);
  //     });
  //   },

  //   observerCallback(entries, object) {
  //     entries.forEach(function(entry, i) {
  //       if (!entry.isIntersecting) return;
  //       entry.target.classList.add(website.actAnime);
  //       object.unobserve(entry.target);
  //     });
  //   },
  // }


  // const Parallax = {

  //   init() {
  //     parallax = {
  //       nodeList   : document.querySelectorAll('.js-parallax'),
  //       gap        : 0.08,

  //       decoList   : [],
  //     };

  //     let targets = Array.prototype.slice.call(parallax.nodeList, 0);
  //     targets.forEach((target) => {
  //       if ( target.dataset.para ) {
  //         let arr = target.dataset.para.split(',');
  //         parallax.decoList.push( [target, arr] );
  //       }
  //     });
  //   },

  //   decoImg() {
  //     parallax.decoList.forEach((target) => {
  //       let targetRect = target[0].getBoundingClientRect(),
  //           parentNode = document.querySelector(target[1][0]),
  //           parentRect = parentNode.getBoundingClientRect(),
  //           diff       = targetRect.top - parentRect.top,
  //           gap        = (target[1][3]) ? Number(target[1][3]): parallax.gap,
  //           translateX,
  //           translateY;

  //       if ( (targetRect.top - website.winHeight) <= 0 ) {
  //         if ( target[1][1] == 'X' ) {
  //           translateX = ( target[1][2] == 'reverse' ) ? -((targetRect.top - diff) * gap): (targetRect.top - diff) * gap;
  //           target[0].style.transform = 'translate3d(' + translateX + 'px, 0px, 0px)';
  //         } else {
  //           translateY = ( target[1][2] == 'reverse' ) ? -((targetRect.top - diff) * gap): (targetRect.top - diff) * gap;
  //           target[0].style.transform = 'translate3d(0, ' + translateY + 'px, 0)';
  //         }

  //       }
  //     });
  //   },

  // }


  const Totop ={
    init(){
      totop = {
        targetClass : '.js-totop',
        speed       : 600,
      }
      this.check();
      this.action();
    },
    check(){
      if( website.scrollY > 100 ){
        $( totop.targetClass ).addClass('is-act').fadeIn(400);
      }else{
        $( totop.targetClass ).removeClass('is-act').fadeOut(400);
      }
    },
    action(){
      $( totop.targetClass ).on('click',function(){
        $('body,html').animate({scrollTop: 0}, totop.speed, 'swing');
        return false;
      });
    }
  }

  const DrawerMenu = {

    init() {
      drawer = {
        panel     : 'js-so_panel',
        menu      : 'js-so_menu',
        duration  : 10,
        // openclass : 'slideout-open',
        toggleList: '#js-so_toggle',
        closeList : '#js-so_panel, #js-so_menu .js-scroll',
      };

      this.menu();
    },

    menu() {
      let slideoutInst = new Slideout({
        'panel': document.getElementById(drawer.panel),
        'menu': document.getElementById(drawer.menu),
        'duration': drawer.duration,
        // 'openclass': drawer.openclass,
      });

      let nodeList = document.querySelectorAll(drawer.toggleList);
      let node = Array.prototype.slice.call(nodeList,0);
      node.forEach(function(elem, index){
        elem.addEventListener('click', function() {
          slideoutInst.toggle();
        });
      });

      let nodeListClose = document.querySelectorAll(drawer.closeList);
      let nodeClose = Array.prototype.slice.call(nodeListClose,0);
      nodeClose.forEach(function(elem, index){
        elem.addEventListener('click', function() {
          slideoutInst.close();
        });
      });
    },

  }


  const Wordpress = {

    init() {
      wp = {
        editorTarget: 'js-editor',
        tableClass  : 'c-tbl_responsive',
        tableInnerClass  : 'c-tbl_responsive__inner',
        iframeclass : 'c-iframe',
      }

      this.wpEditor();
    },

    wpEditor() {
      if ( $('.' + wp.editorTarget).length ) {
        $( '.' + wp.editorTarget + ' table' ).each(function() {
          $(this).wrap('<div class="' + wp.tableClass + '"><div class="' + wp.tableInnerClass + '"></div></div>');
        });
        $( '.' + wp.editorTarget + ' iframe' ).each(function() {
          $(this).wrap('<div class="' + wp.iframeclass + '"></div>');
        });
      }
    }

  }

  // const Slick = {

  //   init() {
  //   },

  //   topKey() {
  //     var autospeed = 5000;
  //     $('.js-keyTop_slide').on('init',function(event,slick){
  //       $('.slick-current').addClass('is-anime');
  //       $('.js-keyTop_progress').html('<div class="current"></div><div class="bar"><span></span></div><div class="next"></div>');
  //       $('.js-keyTop_progress').find('.current').text('0'+(slick.currentSlide+1));
  //       if(slick.currentSlide+2 <= slick.slideCount){
  //         $('.js-keyTop_progress').find('.next').text('0'+(slick.currentSlide+2));
  //       }else{
  //         $('.js-keyTop_progress').find('.next').text('01');
  //       }
  //       if(website.winWidth < website.breakpoint.sp){
  //         $('.js-keyTop_progress').find('.bar').children('span').animate({width: '100%'},autospeed,'linear');
  //       }else{
  //         $('.js-keyTop_progress').find('.bar').children('span').animate({height: '100%'},autospeed,'linear');
  //       }
  //     }).slick({
  //       fade: true,
  //       autoplay: true,
  //       autoplaySpeed: autospeed,
  //       speed: 1000,
  //       cssEase: 'linear',
  //       arrows: false,
  //       dots: true,
  //       appendDots : $('.js-keyTop_dots'),
  //       pauseOnFocus: false,
  //       pauseOnHover: false,
  //       responsive: [
  //         {
  //           breakpoint: website.breakpoint.ss,
  //           settings: {
  //             dots: false,
  //           }

  //         },],
  //     }).on({
  //       beforeChange: function(event, slick, currentSlide, nextSlide){
  //         $(".slick-slide", this).eq(currentSlide).addClass('is-prev');
  //         $(".slick-slide", this).eq(nextSlide).addClass('is-anime');
  //       },
  //       afterChange: function(event,slick,currentSlide){
  //         $(".slick-slide.is-prev", this).removeClass("is-prev is-anime");
  //         if(website.winWidth < website.breakpoint.sp){
  //           $('.js-keyTop_progress').find('.bar').children('span').css({
  //             'height':'100%',
  //             'width':'0%'
  //           });
  //         }else{
  //           $('.js-keyTop_progress').find('.bar').children('span').css({
  //             'height':'0%',
  //             'width':'100%'
  //           });
  //         }
  //         $('.js-keyTop_progress').find('.current').text('0'+(slick.currentSlide+1));
  //         if(slick.currentSlide+2 <= slick.slideCount){
  //           $('.js-keyTop_progress').find('.next').text('0'+(slick.currentSlide+2));
  //         }else{
  //           $('.js-keyTop_progress').find('.next').text('01');
  //         }
  //         if(website.winWidth < website.breakpoint.sp){
  //           $('.js-keyTop_progress').find('.bar').children('span').animate({width: '100%'},autospeed,'linear');
  //         }else{
  //           $('.js-keyTop_progress').find('.bar').children('span').animate({height: '100%'},autospeed,'linear');
  //         }
  //       }
  //     });
  //   },
  // }

  const Loading = {
    init(){
      loading = {
        targetClass : '.js-loading',
      }
    },
    start(){
      $(loading.targetClass).addClass('is-act');
    },
    complete(){
      $('body').addClass('is-loaded');
    }
  }

  const LoopSlide = {
    init(){
      loopSlide = {
        targetClass : '.js-loopSlide',
      }
      this.action();
    },
    action(){
      if($(loopSlide.targetClass).length){
        $(loopSlide.targetClass).addClass('is-act').infiniteslide({
          speed:30,
          pauseonhover: false,
          responsive : true,
        });
      }
    }
  }

  // const Form = {
  //   init(){
  //     form = {
  //       targetWrap   : '#js-mailform',
  //       rowItem      : '.js-form_item',
  //       inputItem    : '.js-form_input',
  //       confirmItem  : '.js-form_confirm',
  //       inputCheck   : '.js-form_icheck',
  //       confirmBtn   : '#js-btn_confirm',
  //       backBtn      : '#js-btn_back',
  //     }
  //     this.prepare();
  //     this.valid();
  //     this.confirm();
  //     this.back();
  //   },
  //   prepare(){
  //     $(form.targetWrap).prepend('<input type="hidden" name="javascript_action" value="true">');
  //     $(form.targetWrap).find(form.confirmItem).each(function(){
  //       $(this).hide();
  //     });
  //     $.extend($.validator.messages, {
  //       required: '必須項目です。',
  //       email: 'メールアドレスの形式が正しくありません。',
  //       postal: '郵便番号の形式が正しくありません。',
  //       alphanum: '半角英数字で入力してください。',
  //       alpha: '半角英字で入力してください。',
  //       phone: '半角数字と「-」のみ使用できます。',
  //       equalTo: '同じ値を入力してください。',
  //       number: '半角数字で入力してください。',
  //       digits: '半角数字で入力してください。',
  //       url: 'URLの形式が正しくありません。',
  //       date: '日付の形式が正しくありません。',
  //       minlength: $.validator.format( "最低 {0} 文字以上で入力してください。" ),
  //       maxlength: $.validator.format( "最大 {0} 文字以下で入力してください。" ),
  //       min: $.validator.format( "{0} より大きな数にしてください。" ),
  //       max: $.validator.format( "{0} より小さな数にしてください。" ),
  //       step: '{0} の倍数を入力してください。',
  //       kana: '全角ひらがな、カタカナで入力してください。',
  //       hiragana: '全角ひらがなで入力してください。',
  //       katakana: '全角カタカナで入力してください。',
  //     });

  //     formMethods = {
  //       postal: function(value, element){
  //         return this.optional(element) || /^[0-9]{3}-?[0-9]{4}$/.test(value);
  //       },
  //       alphanum: function(value, element){
  //         return this.optional(element) || /^[a-zA-Z0-9_]+$/.test(value);
  //       },
  //       alpha: function(value, element){
  //         return this.optional(element) || /^[a-zA-Z]+$/.test(value);
  //       },
  //       phone: function(value, element){
  //         return this.optional(element) || /^[0-9\-\ ]+$/.test(value);
  //       },
  //       kana: function(value, element){
  //         return this.optional(element) || /^[ぁ-んァ-ヶー　\ ]+$/.test(value);
  //       },
  //       hiragana: function(value, element){
  //         return this.optional(element) || /^[ぁ-んー　\ ]+$/.test(value);
  //       },
  //       katakana: function(value, element){
  //         return this.optional(element) || /^[ァ-ヶー　\ ]+$/.test(value);
  //       },
  //     }
  //     $.each(formMethods, function(key){
  //       $.validator.addMethod(key, this);
  //     });
  //   },
  //   valid() {
  //     $(form.targetWrap).validate({
  //       rules : {
  //         'お名前' : {
  //           required: true
  //         },
  //         'メールアドレス' : {
  //           required: true,
  //           email: true
  //         },
  //         '電話番号' : {
  //           phone: true,
  //           minlength: 10,
  //           maxlength: 14
  //         },
  //         'お問い合わせ内容' : {
  //           required: true,
  //         },
  //       },
  //       errorPlacement: function(error, element) {
  //         name = element.attr("name");
  //         if(
  //           name == 'xxx'
  //         ) {
  //           var str = name.replace(/\[\d*\]/g, '');
  //           error.appendTo($("#" +str+ "_error"));
  //         } else{
  //           error.insertAfter(element);
  //         }
  //       }
  //     });
  //   },
  //   confirm(){
  //     $(form.confirmBtn).on('click',function(){
  //       let formCheck = $(form.targetWrap).valid();
  //       if(formCheck) {
  //         $(form.rowItem).each(function(){
  //           let iInput    = $(this).children(form.inputItem),
  //               iConfirm  = $(this).children(form.confirmItem);

  //           if ( iInput.length ) {
  //             if ( iInput.hasClass(form.inputCheck) ) {
  //               var chText = '';
  //               iInput.find('input').each( function( index, element ) {
  //                 if ( $(this).prop('checked') == true ) {
  //                   if ( chText != '' ) { chText = chText + '、'; }
  //                   chText = chText + Form.sanitizing($(this).val());
  //                   iConfirm.html( chText );
  //                 }
  //               });
  //             } else {
  //               var iInputTag = iInput.find('input,select,textarea');
  //               if ( iInputTag.length ) {
  //                 var iVal = iInputTag.val();
  //                 if ( typeof iVal !== 'undifined' ) {
  //                   iConfirm.html( sanitizing(iVal) );
  //                 }
  //               }
  //             }
  //           }

  //           iInput.hide();
  //           iConfirm.show();
  //         });
  //       }
  //       let position = $(form.targetWrap).offset().top - 50;
  //       $('body,html').animate({scrollTop:position}, speed, 'swing');
  //       return false;
  //     });
  //   },
  //   back(){
  //     $(form.backBtn).on('click',function(){
  //       $(form.rowItem).each(function(){
  //         let iInput    = $(this).children(form.inputItem),
  //             iConfirm  = $(this).children(form.confirmItem);
  //             iInput.show();
  //             iConfirm.hide();
  //       });
  //       let position = $(form.targetWrap).offset().top - 50;
  //       $('body,html').animate({scrollTop:position}, speed, 'swing');
  //       return false;
  //     });
  //   },
  //   sanitizing(str){
  //     var before = new Array('&','"',"'","<",">","\n","\t","\\n");
  //     var after = new Array('&amp;','&quot;','&rsquo;',"&lt;","&gt;","<br />"," ","<br />");
  //     for( var i=0; i < before.length; i++ )
  //       str = str.replace(new RegExp(before[i],'g'), after[i]);
  //     return str;
  //   }
  // }

  // const Pagenavi = {
  //   init(){
  //     navi = {
  //       self: 'js-pagenavi',
  //     }
  //     this.check();
  //   },
  //   check(){
  //     let target={};
  //     if($('.' + navi.self).length){
  //       $($('.'+navi.self).get().reverse()).each(function(){
  //         let href = $(this).attr('href')
  //         target[href] = $(href).offset().top;
  //         if($(href).offset().top - website.winHeight/2 < website.scrollY){
  //           $(this).parent('li').siblings('li').children('.'+navi.self).removeClass('is-act');
  //           $(this).addClass('is-act');
  //           return false;
  //         }
  //       });
  //     }
  //   }
  // }

  const Top = {

    init() {
      $('.js-keyTop').css({
        'padding-top':website.headTag.outerHeight()+'px',
        'margin-top':-website.headTag.outerHeight()+'px',
      });
      LoopSlide.init();
    },
    update() {
      $('.js-keyTop').css({
        'padding-top':website.headTag.outerHeight()+'px',
        'margin-top':-website.headTag.outerHeight()+'px',
      });
    },
    check() {
      $('.js-keyTop').addClass('is-load');
    }
  }

  const Products = {
    init (){
      // Slick.prodKey();
    }
  }

  const Contact = {

    init() {
      // Form.init();
    },

    update() {
    },

  }

  const App = {

    init() {
      this.set();
      // this.update();
    },

    set() {
      Modules.init();
      DrawerMenu.init();
      Totop.init();
      // PageScroll.init();
      // Timer.init();
      // Iscroll.init();
      Wordpress.init();
      Timer.init();
      if ( website.page == 'top' ) {
        WinLoad.add( Top.init );
      } else if( website.page == 'contact' ) {
        // WinLoad.add( Form.init );
      }


      WinLoad.add( Animation.check );
      // WinLoad.add( Modules.megaMenu );
      // WinLoad.add( Iscroll.check );

      WinResize.add( Modules.iosCheck );
      WinResize.add( Modules.deviceCheck );

      WinScroll.add( Animation.check );
      WinScroll.add( Totop.check );
      // WinScroll.add( Iscroll.check );


      WinLoad.init();
      WinResize.init();
      WinScroll.init();
    },

    // update() {
    //   website.animationFrame = window.requestAnimationFrame( App.update );
    //   website.winHeight      = window.innerHeight;
    //   website.scrollY        = window.pageYOffset;

    //   if ( website.isDesktop ) {
    //     PageScroll.smooth();

    //     if ( website.page == 'top' ) {
    //       Parallax.decoImg();
    //     }
    //   }
    //   // else {
    //   //   PageScroll.set();
    //   // }
    // },

  }


  App.init();

})();


})(jQuery);
